import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    // 有Tab的router，有commonLayer
    {
        path: '/',
        name: 'CommonLayerLayout',
        component: () => import('../layout/CommonLayerLayout.vue'),
        children: [{
            path: '/rights-no',
            name: 'RightsNo',
            component: () => import('../views/RightsNo.vue')
        }, {
            path: '/multi-rights',
            name: 'MultiRights',
            component: () => import('../views/MultiRights.vue')
        }, {
            path: '/nine',
            name: 'Nine',
            component: () => import('../views/NinePointNine.vue')
        }, {
            path: '/rights-no1',
            name: 'RightsNo1',
            component: () => import('../views/RightsNo1.vue')
        }, {
            path: '/composite/rights/:rightsNo',
            name: 'CompositeRights',
            component: () => import('../views/CompositeRights.vue')
        }, {
            // 兼容上面链接过长的情况
            path: '/rights/:rightsNo',
            name: 'Rights',
            component: () => import('../views/RightsNo.vue')
        }, {
            // 兼容上面链接过长的情况
            path: '/rights1/:rightsNo',
            name: 'Rights1',
            component: () => import('../views/RightsNo1.vue')
        }, {
            // 直接卡号转二维码、128条形码页面
            path: '/ticket1/:account',
            name: 'HomePage1',
            component: () => import('../views/Ticket1.vue'),
        }, {
            // 直接卡号转二维码、128条形码页面
            path: '/ticket/:account',
            name: 'HomePage',
            component: () => import('../views/Ticket.vue'),
        }, {
            path: '/ticket-starbucks/:account',
            name: 'TicketStarbucks',
            component: () => import('../views/TicketStarbucks.vue')
        }, {
            // 直冲类需要用户输入直冲账号的情况
            path: '/input-account/:rightsNo',
            name: 'InputAccount',
            component: () => import('../views/InputAccount.vue')
        }, {
            path: '/rights-result/:rightsNo',
            name: 'RightsResult',
            component: () => import('../views/RightsResult.vue')
        }, {
            // 直冲类需要用户输入直冲账号的情况
            path: '/input-account1/:rightsNo',
            name: 'InputAccount1',
            component: () => import('../views/InputAccount1.vue')
        }, {
            path: '/rights-result1/:rightsNo',
            name: 'RightsResult1',
            component: () => import('../views/RightsResult1.vue')
        }]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
