import Vue from 'vue'
import App from './App.vue'
import router from './router';
import {Notify} from 'vant';
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use(Notify);
Vue.use(VueCookies);

export default new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
